import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import '../../../styles/app.scss';

import ChrisPearsonPNG from '../../../assets/images/chris-pearson.png';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Comment from HSBC UK’s Head of Intermediary Mortgages, Chris Pearson</h1>
    <p>According to <u>Wikipedia</u> “The show must go on” is a phrase used in showbiz meaning that regardless of what happens, whatever show has been planned still has to be staged for the waiting patrons. It was also one of the many successful songs by Freddie Mercury and Queen when I was growing up. But it is a phrase that really typifies our approach during the pandemic.</p>
    <p>Not only have we maintained a high level of support to our broker partners throughout the most testing times of the pandemic, with operations across the globe providing 24/7 support, we have in fact accelerated our broker on-boarding, and have now reached the milestone of 300 broker partners. An achievement I am extremely proud of.</p>
    <img className="chris-pearson" src={ChrisPearsonPNG} alt="Chris Pearson" />
    <p>Considering we had 176 at the start of the pandemic, adding 124 brokers to hit the 300 mark in under 11 months is quite an achievement, especially as we have all been working from home and dealing with home-schooling, shielding and self-isolation, like everyone else. I take my hat off to my team for the progress that has been made.</p>
    <p>To take a step back, we embarked on partnering with brokers in late 2014 to ensure we’re there to serve customers irrespective of their channel of choice. Midland Bank, as we were known then, started offering mortgages just over 40 years ago and were wholly focused on being a direct only mortgage provider. That worked for us for a long time but we were missing out on a massive part of the market.</p>
    <p>We didn’t want to bite off more than we could chew, offer attractive rates and not be able to provide those mortgages in a timely way. That wouldn’t have helped us, brokers or their clients who wanted to move onto or up the property ladder. We made a conscious and logical decision to steadily grow that side of the business to make sure we were had smooth growth and were always able to deliver a top quality service to our broker partners.</p>
    <p>Since progressing from being a direct-only mortgage provider we have continually evolved in the way we work with brokers in virtually every way. At the beginning we introduced a dedicated broker portal and website, replacing our antiquated system, improved how we could be contacted through live chat and introduced mobile BDMs.</p>
    <p>We have been able to streamline how we work in virtually every way, removing friction and embracing technology, all of which has helped us reduce our average time to offer from seven weeks a few years ago to just over one now. We have come a long way in a relatively short period of time.</p>
    <p>There have been challenges, hurdles, obstacles and difficulties along the way, with the pandemic presenting challenges that have never been seen before in our industry.</p>
    <p>The first challenge for us was enabling our workforce to work effectively from home, not just in the UK, but also in Manila and Hyderabad too. Within a week of lockdown we were managing our broker applications effectively, but like other businesses childcare, quarantine, self-isolation and shielding brought additional challenges.</p>
    <p>The development of a payment holiday option, something we hadn’t offered until then, was done and up and running within two weeks, and I know brokers were fighting fires on that front too, trying to keep their clients up to speed on movements across the industry. We have now helped over 60k mortgage customers with payment holidays.</p>
    <p>‘Lockdown 1’ presented uncharted difficulties in the valuation of properties, so a move towards more automated valuations was brought forward to keep the cogs turning and we re-started physical valuations as soon as the lockdown rules eased.</p>
    <p>Communication with brokers was key. We increased our broker Live Chat functionality to allow case updates and have had over 35,000 conversations with our brokers, this is in addition to answering 240k calls during this time.</p>
    <p>Communicating changes that were needed surrounding furloughed applicants or those with reduced income were important in addition to making processes easier, like removing the need for original certified documents to be provided as common sense told us they weren’t particularly viable.</p>
    <p>We were adamant that we would stay in the 90% LTV space for as long as we could to help support the mortgage market. We had to make certain changes to ensure we were able to work our pipeline of business, but it got to a stage where we were not able to. So for a short time, and with a heavy heart, we withdrew from the market. From the new year we returned, and have recently reinstated the inclusion of overtime, commission and bonuses to support a mortgage application, which will be important for many.</p>
    <p>Lockdowns 2 and 3 have had their own difficulties for us, like every other business, but hopefully there is light at the end of the tunnel. We know there has been a lot of change over the last year, policies, LTIs, and even restrictions in lending and keeping abreast of everything that has been going on will have been a mammoth task, but you have and you should be rightly proud.</p>
    <p>No-one should under-estimate the vital role that brokers play. Many brokers will have an established relationship with their clients, helping them with diverse financial needs or a full market check, but like our own in-house advisers, also giving home-buyers, especially first-timers, important peace of mind during what can be a complex and daunting process. They are even more relied upon now, helping people navigate the, often, rapidly changing policies and options available to them at this difficult time.</p>
    <p>Whilst we’ll always look to provide a comprehensive range of competitive products, our service is at the heart of everything we do. Listening to brokers and trying to help with every case, quickly and efficiently, always with the most appropriate point of contact to chat through a case when needed.</p>
    <p>We want to be known in the market for more than our attractive mortgage rates. We want to be seen to be providing brokers with great service, swift turnaround times and providing what we say we will when we say we will.</p>
    <p>In order to do that to keep up with demand we have increased the size of our broker-facing teams to nearly 200 people, so whether it is face-to-face support, over the phone help or providing a resolution by live chat, we’re always here to help. Thanks to our international presence and global footprint, we are processing documents, making underwriting decisions and making mortgage offers day and night.</p>
    <p>The fact we won 20 industry awards recognising both collective and personal excellence during 2020 shows that we must be doing something right. We really do appreciate the recognition, it is the cherry on the cake to know that our efforts are noticed and valued.</p>
    <p>2021 will see HSBC UK continue to push the boundaries and support our broker partners through whatever this year holds in store and beyond.</p>
    <p>We are delighted we were able to maintain a high level of support throughout the most testing of times during the height of the pandemic in 2020 and we know that our broker partnerships are enduring through the good times as well as the more challenging times. We’ll be there to support.</p>

  </NewsArticle>
)

export default Article
